.intro-section{
    @media(max-width: @screen-sm-max) {
        padding-top: 150px;
    }

}

.contact-section{
    width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: #FFFFFF;

    .form{
        background-color: #FFFFFF;
        box-shadow: 0px 0px 80px 0px rgba(0,0,0,.1);
        border: transparent;
        padding: 20px 20px 50px 20px;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        &:hover {
            box-shadow: 0 0 120px 0 rgba(0, 0, 0, 0.1);
        }
    }

    legend{
        text-align: center;
        font-size: 26px;
        border-bottom: 3px solid #79ef8e;
    }

    .btn{
        -webkit-transition: .5s ease;
        -moz-transition: .5s ease;
        -o-transition: .5s ease;
        transition: .5s ease;
        &:hover{
            top: -2px;
            position: relative;
        }
    }
}



#footer{
    padding-bottom: 30px;
    padding-top: 30px;
    font-size: 16px;
    color: #79ef8e;
    background-color: #262324;
    span{
        font-weight: bold;
        color: #e6e6e6;
    }
}