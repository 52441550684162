@import 'node_modules/slick-carousel/slick/slick';
@import 'node_modules/slick-carousel/slick/slick-theme';
@slick-font-path: "/assets/";
@slick-font-family: "slick";
@slick-loader-path: "/assets/";

.slick-prev:before, .slick-next:before {
    font-size: 28px;
}

.slick-prev {
    left: 25px;
    z-index: 99;
}

.slick-next{
    right: 25px;
}

.slick-list,
.slick-track {
    height: 100%;
}