
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import 'node_modules/bootstrap/less/bootstrap.less';

@font-family-sans-serif: 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif;
@input-border-radius: 0;
@input-border-focus: #79ef8e;

@media (max-width: @screen-sm-max) {
    .text-center-sm{
        .text-center();
    }
}

